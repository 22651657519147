<template>
  <b-card no-body>
    <b-container fluid class="p-2 pb-0 mb-0">
      <b-row class="p-0 mb-0" align-v="center">
        <b-col cols="auto" class="mr-auto pl-1">
          <h1 class="text-primary font-weight-bolder" style="font-size: 2rem">SmartFarm</h1> 
        </b-col>

        <b-col cols="auto" class="mb-0 pb-0">
          <b-img width="100%/7" :src="require('@/assets/images/logo/logo_tera.webp')" fluid alt="logo_tera_solusi">
          </b-img>
        </b-col>
 
      </b-row>
    </b-container>
    <hr/>

    <b-card-body class="pl-3 pr-3 mt-0 pt-1">
      <b-card-text class="font-weight-bolder">Sistem Informasi SmartFarm</b-card-text>
      <b-card-text>Versi Aplikasi : SmartFarm V.1.0-2022 </b-card-text>
      <b-card-text class="mb-2">
        Copyright  © {{ new Date().getFullYear() }} PT. Tera Tekno Solusi. All Right Reserved
      </b-card-text>
      <b-card-text>
        Aplikasi Sistem Informasi SmartFarm dilisensikan di
      bawah PT. Tera Tekno Solusi, diberikan kepada
      pengguna:
      </b-card-text>
      <b-card-text class="font-weight-bolder">SMK Negeri 1 Tulung Klaten</b-card-text>
    </b-card-body>
  </b-card>
</template>

<script>
import { BImg,BRow, BCol, BCard, BContainer, BCardBody, BCardText } from 'bootstrap-vue'

export default {
  components: {
    BImg,
    BCard,
    BContainer,
    BCardBody,
    BCardText,
    BRow,
    BCol,
  },
}
</script>

<style>

</style>
